.pos-profile-list{
	&_card {
		@include clearfix;
		width: 100%;
		padding: 0;
	}
	
	&__wrapper {
		@include clearfix;
    }
    
	&__header {
		width: 100%;
		display : table;
		@include make-header-title;

    	&_save-button {
			float: right;
			width: 40%;
			margin-top: 0.9375rem;
			
			>button {
				@include make-button($brand-color-11, $brand-color-2, capitalize);
				@include button-size(0 ,0, 15px, 0, 4px, 75%);
				float: right;
			    height: 1.875rem;
			}
		}
		
	}
	
	&__profile-dropdown {
    	width: 25%;
    	margin-bottom: 0.625rem;
	}
	
	&__status-msgs{
		text-align: center;
    	padding: 1rem;
    	font-size: 1.5rem;	
	}
	
	&__add-profile{
		@include clearfix;
		float: left;
		width: 100%;
		
		box-shadow: 0 0 15px rgba(0,0,0,.25);
	    background-color: $brand-color-11;
	    margin-bottom: .9375rem;
	    padding: .625rem .9375rem;
		margin-top: $base-margin/2;
		
		&_profile {
			@include clearfix;
			float: left;
			width: 100%;
			
			.profile {
				&__profile-input {
					float: left;
					width: 40%;
					
					&_label {
						float: left;
						margin-top: 0.5rem;
			    		color: $brand-color-2;
					}
					&_text {
						float: left;
						width : 70%;
					    padding: .5rem 1rem;
					    background: #light;
					    font-size: .875rem;
					    vertical-align: middle;
					    line-height: normal;
					    border: 1px solid #d0d0d0;
					    max-height: 30px;
						margin-left: 1rem;
						border-radius: 4px;
					    margin-top: 0.125rem;
					}
					&_error {
						float: left;
    					margin-left: 1rem;
					}
				}
				
				&__save-button {
					float: left;
					width: 20%;
					
					>button {
						@include make-button($brand-color-11, $brand-color-2, capitalize);
						@include button-size(0 ,0, 15px, 0, 4px, 100%);
						float: right;
					    height: 1.875rem;
					    margin-top: 0.125rem;
					}
				}
			}
		}
		
		&_add-button{
/* 			float: right;  */
/* 			margin-top: 1rem; */
			text-align:center;
			margin-top: 1rem;
			
			>button{
				margin-bottom: 0.725rem;
				@include  make-button($light, $brand-color-1,none);
				@include  button-size(0, 0, 20px, inherit, 0, 20%);
			}
		}
	}
	
	 &__table{
    	background-color: $light;
    	width : 100%;
    	table-layout: fixed;
    	border: 1px solid $brand-color-4;
    	
    	&_header{
    		text-transform: capitalize;
	    	background-color: $brand-color-3;
    		color: $brand-color-2;
    	}
    	
    	&_header,&_data{
		    padding-top: $base-padding/6;
		    padding-bottom: $base-padding/6;
		    text-align: center;

    	}
    	&_data{
    		.pos-toggle-button__enabled-bar, .pos-toggle-button__disabled-bar{
    			&:hover{
    			    cursor: pointer;
    			}
    		}
    	}
    	
    	&_row{
	    	border-bottom: 1px solid $brand-color-4;
    	}
    }
    
    &__error-msg{
    	color: $royal-red;
    }
    &__loader{
    	@include create-loader-background;
		margin: 0;
    }
}