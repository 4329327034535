.pos-agent-refund-details{
	@include create-main-wrapper;
	
	&__content{
		 @include clearfix;
		
		&_product-info{
		    @include clearfix;
			padding : $base-padding/3;
			margin : $base-padding/3;
	    }
	}
}