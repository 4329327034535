.pos-refund-product-details{
@include create-main-wrapper;

	&__product-info{
		@include clearfix;
		&_refund-form{
			padding:  $base-padding/2;
		}
		
		&_header{
			.header{
				&__title{
					font-size :  20px;
				}	
			}
		}
	}
}