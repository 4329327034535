@mixin task-filter-button($color:$brand-color-2) {
  @include make-button($color, $brand-color-11, capitalize);
  @include button-size(1rem, 0.5rem, $font-size-h5, inherit, 5px, 100%); //1 rem 
  border-right: solid 1px $brand-color-10;
  border-left: solid 1px $brand-color-10;
  text-align: center;
}

.pos-task-filter {

  @include create-main-wrapper;
  &_wrapper {
    @include clearfix;
  }

  width: 100%;
  @include clearfix;
  border: 5px solid $brand-color-3;
  margin-bottom: 1rem;
  width: 100%;

  &__filter {
    @include clearfix;
    width: 100%;
    background-color: $gray-lighter;
    float: none;
    padding: 0.5rem;
    margin-bottom: 0.5rem;

    &_header {
      font-size: 1rem;
      padding-bottom: .5rem;
      font-weight: bold;
      text-align: center;
    }

    &_content {
      @include clearfix;
      width: 100%;

    }

    @include make-responsive($desktop-sm) {
      width: 50%;
      float: left;
    }
  }
  &__filter:nth-child(odd) {
    @include make-responsive($desktop-sm) {
      border-right: 0.25rem solid $light;
    }
  }

  &__filter:nth-child(even) {
    @include make-responsive($desktop-sm) {
      border-left: 0.25rem solid $light;
    }
  }
  
  &__apply-btn{
    @include clearfix;
    width: 100%;
    padding: 0.5rem;
    @include make-responsive($desktop-sm) {
      float: right;
      @include clearfix;
      width: 25%;
    }
    > button{
      @include make-button($light, $brand-color-2, capitalize, $light, darken($brand-color-2,20));
      @include button-size(0.5rem, 0.25rem, $font-size-h5, inherit, 5px, 100%); 
    }
  }
  
  .filter-content {
    @include clearfix;
    &__due-dates {

      &_today,
      &_tomorrow,
      &_upcoming,
      &_over-due {
        @include task-filter-button();
        padding: 1rem .25rem;
        @include make-responsive($desktop-sm) {
       		padding: 1rem .5rem;
        }
      }
    }
    &__due-dates, &__priorities, &__assignee, &__task-statuses {
      @include clearfix;
      .fa {
        font-size: $font-size-h4;
      }
      float: left;
      width: 25%;
    }

    &__priorities, &__task-statuses {
      width: 33.33%;
    }

    &__task-statuses {
      &_new {
        @include task-filter-button($gray-light);
      }

      &_in-progress {
        @include task-filter-button($brand-color-8);
      }

      &_completed {
        @include task-filter-button($green);
      }

      >button {
        padding: 0.75rem;
        font-size: 1rem;
        @include make-responsive($desktop-sm) {
          font-size: 1.125rem;
        }
      }
    }
    &__priorities {
      &_high,
      &_low,
      &_medium {
        @include task-filter-button();
      }

      &_high {
        .fa {
          color: $brand-color-1;
        }
      }

      &_medium {
        .fa {
          color: $star-rating;
        }
      }
    }

    &__assignee-me {
      width: 35%;
      float:left;
      &_label{
        @include task-filter-button();
        padding: $base-padding/2 $base-padding/7;
        @include make-responsive($desktop-sm) {
       		padding: 0.95rem;
        }
      }
      &_active{
        @include make-button($light, $brand-color-2, capitalize);
      }
      .fa {
        padding-right: 0.125rem;
      }
    }
    
    &__assignee-multiple {
      width: 65%;
      float: left;

      .multi-select .multi-select-field {
        border: 1px solid $brand-color-10;
        border-top: none;
        border-bottom: none;
        border-radius: 5px;
        color: $brand-color-2;
        max-height: 50px;
        height: 50px;
        font-size: 0.95rem;
      }

      .multi-assignee-select {
        @include clearfix;
        width: 90%;
        float: right;

        &__name {
          float: left;
          width: 100%;
        }
      }

      .dropdown-menu>li>a {
        height: 45px;
        line-height: 3;
      }

      .dropdown-menu>li>a:hover {
        display: table;
        width: 100%;
      }
    }

    &__active-filter {
      @include make-button($light, $brand-color-2, capitalize);
      border-right: solid 1px $light;
      border-left: solid 1px $light;
    }
    @include make-responsive($desktop-sm) {
      border: 5px solid $brand-color-3;
      padding: 1rem;
    }
  }

}