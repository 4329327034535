.pos-cash-receipt-list {

	&__header-container {
		cursor : pointer;
	}
	
	&__header {
		@include clearfix;
		@include make-header-title;
		width : 100%;
		
		&_title {
			width : 50%;
			float:left;
		}
		
		&_quick-search {
			float: right;
		}
    	
		&_search-box {
			float: right;
			margin-top: 0.5rem;
		}
		
		&-with-remote-filter {
			width:75%;
		}
		
		&_action-bar {
			float: right;
			width:100%;
			position:relative;
		}
		
		&_button {
			float: right;
			margin-right: $base-margin/2;
		}
		
		&_add-button {
			@include make-button($brand-color-11, $brand-color-2, capitalize);
			@include button-size($base-padding/3 ,$base-padding/3, $base-padding/2, 0, 0.25rem, 100%);
			height: 1.875rem;
		}
	}
	
	&__content {
		&_headers {
		    @include clearfix;
			@include make-row;
	   		padding: 0;
	   		margin-bottom: $base-margin/2;
	   		box-shadow: 0 0 $base-padding/3 rgba(0, 0, 0, .25);
			background-color: $brand-color-3;
			text-transform: capitalize;
			color: $brand-color-2;
		}
	}
	
	.header {
		text-transform: none;
		@include make-one-fourth;
		padding: 0.3rem;
		width: 25%;
	}
	
	.cash-receipt-list {
		@include create-row-card;
		@include make-row;
		@include create-card-content;
		@include clearfix;
	    
	    &__card {
			box-shadow: $row-shadow-color;
			background: $light;
			color: $gray-dark;
			&:hover {
				color: black;
				background-color: $brand-color-3;
			}
	    	@include clearfix;
			width : 100%;
			padding: 0.3125rem;
			margin-bottom: $base-margin/2;
		}
		
		&__document-no-details, &__note-details, &__customer, &__amount-details {
			width: 25%;
		 	float: left;
		}
		
		&__document-no, &__invoice-no {
			font-size: 1rem;
			color: $brand-color-2;
			min-height: 1.5rem;
		}
		
		&__download-icon {
			color: $brand-color-12;
		}
		
		&__date, &__currency {
			font-size: 0.75rem;
		}
		
		&__customer {
			font-size: 1rem;
		}
		
		&__amount {
			font-size: 1.125rem;
			color: $brand-color-1;
		}
	}
	
	.pos-cash-receipt-list {
		&__status-msgs {
			text-align: center;
			padding : 1rem;
			font-size: $font-size-h3;
		}
	}
}