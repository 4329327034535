.queue-dashboard {
  width: 100%;
  padding-top: 2rem;
  padding-right: 2rem;
  padding-left: 0;
  padding-bottom: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  flex-wrap: wrap;
  color: $gray-dark;
  position: relative;
  &__table {
      width: 57%;
      text-align: center;
      position: unset;
	  display: inline-block;
      overflow-x: scroll;
      overflow-y: visible;
	  padding-left: 0.5rem;
	  tbody{
		width: 100%;
        display: inline-table;
		table-layout: fixed;
	  }
      &_category{
        width:13%;
        text-align: right;
        padding-right: 0.5rem;
		position: absolute;
		left: 8%;
		white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &_link {
        padding-left: 1rem;
        border: 1px solid;
		width:200px;
      }
  }
  &__supplierNames{
	padding: 0 0.5rem;
	word-break: break-all;
	width:200px;
  }
  &__label-left{
    transform: rotate(-0.25turn);
    position: absolute;
    left: 0;     
  }
  &__label-bottom{
    width: 100%;
    padding-bottom: 1.5rem;
    color: $gray-dark;
	text-align: center;
  }
}
 