.pos-cash-receipt-form {
	&__header {
		@include make-row;
		text-transform: capitalize;
  		font-size: 1rem;
  		color: $brand-color-2;
  		
		&_title {
			width:50%;
			float:left;
		}
		
		&_link {
			width:50%;
			float:left;
			&:hover{
				cursor: pointer;
			}
		}
	}
	
	.link__action {
		text-align: right;
		float: right;
	}
	
	.booking__title {
		border-bottom: 1px solid;
		text-transform: uppercase;
		font-size: 1.25rem;
		margin-top: 0.375rem;
		color: $brand-color-2;
	}
	
	&__content {
		float: left;
		width: 100%;
		margin-top: $base-padding/3;
    	
		&_header {
			padding-top: 0.3125rem;
		}
	}
	
	&__card {
		@include clearfix;
		padding: 0 $base-padding/2;
		margin-bottom: $base-margin/2;
		background-color: $light;
		box-shadow: $row-shadow-color;
	}
	
	&__row {
		@include clearfix;
		@include make-row;
		width: 100%;
		padding: 0.5rem;
	}
	
	&__label {
		float: left;
		width: 20%;
		color: $brand-color-2;
		text-align: right;
		padding-right: 1.5rem;
	}
	
	&__field {
		float: left;
		width: 30%;
		padding-left: 0.3125rem;
	}
	
	&__amount {
		width: 100%;
	}
	
	&__control-button {
		@include clearfix;
		width: 100%;
		margin: 0 auto;
		float: right;
		.submit_button {
			@include make-button($light, $brand-color-1, capitalize);
			float: right;
			font-size: 1.25rem;
			margin-top: 1rem;
			margin-bottom: 1rem;
			width: 30%;
			margin-right: 10%;
		}
	}
	
	&__error-msg {
		color: $brand-color-1;
	}
	
	.search-location_autosuggestion .itemcontainer {
		max-height: 31.25rem;
		overflow-y: hidden;
		z-index: 5;
		position: absolute;
		background-color: $light;
		border-top: 1px solid $brand-color-2;
		border-right: 1px solid $gray-lighter;
		border-bottom: 1px solid $gray-lighter;
		border-left: 1px solid $gray-lighter;
		margin-top: -1px;
		top: 100%;
		width: 100%;
	}
	
	.autosuggestion {
		position: relative;
		
		&__item {
			&:hover, &:focus {
				cursor: pointer;
				background-color: $brand-color-3;
			}
			
			&_title {
				color: $brand-color-2;
				margin-bottom: 0.25rem;
				font-size: 0.875rem;
				padding-left: $base-padding/2;
				padding-right: $base-padding/2;
				padding-top: $base-padding/3;
				border-bottom: 1px solid $gray-lighter;
			}
			
			&:last-child p:last-child {
				border-bottom: none;
			}
		}
	}
}