.pos-deposit-note-list  {
	@include create-main-wrapper;

	&_wrapper {
	    @include clearfix;
	}
	
    &__container {

        &_header {
            @include clearfix;
            margin-bottom: $base-margin/2;
            box-shadow: 0 0 0.625rem rgba(0, 0, 0, .25);
            background-color: $brand-color-3;
            text-transform: capitalize;
            color: $brand-color-2;
            display: none;
            width: 100%;
            padding: $base-padding/3;
            @include make-responsive($desktop-sm){
                display: block;
            }
        }
        
        &_content {
            float: none;
            width: 100%;
            @include make-responsive($desktop-sm){
                float: none;
                width: 100%;
                padding: 0rem;
            }
        }
        
        &_content:nth-child(odd) {
            @include make-responsive($desktop-sm){
                padding: 0rem;
            }

        }
        &_content:nth-child(even) {
            @include make-responsive($desktop-sm){
                padding: 0rem;
            }
        }
        
        .icons {
            @include make-responsive($desktop-sm) {
                display: none;
            }
        }
    }

    .header {
	    @include clearfix();
	    width:100%;
        &__task-title {
            @include make-block;
            float:left;
            width:16%;
            text-align: center;
        }
    }

    .content {
        @include create-row-card;
        @include create-card-content;
        &_card {
            @include clearfix;
            box-shadow: $row-shadow-color;
            padding: 0;
        }
        &_row {
            @include clearfix;
            color: $brand-color-22;
            padding: $base-padding/3;
            &:hover {
                color: $dark;
                background-color: $brand-color-3;
            }
            cursor: pointer;
        }
        
        &__details {
            @include clearfix;
            &_task-title {
                @include clearfix;
                float: left;
                width: 16%;
                margin: 0 0 0.25rem 0;
                text-align: center;
                @include make-responsive($desktop-sm) {
                    margin: 0;
                }
            }
            &_number-field{
	           font-family: $font-family-number;
	           &-price{
		         color: $brand-color-1;
	           }
            }
        }
    }
}

  .document-number,.reference-id{
	    width: 14% !important;
   }
.pax-name{
    width: 22% !important;
}