@mixin create-row-card_private {
  &_card {
    @include clearfix;
    padding: 0 $base-padding/2;
    margin-bottom: $base-margin/2;
    background-color: $light;
  }
}
@mixin create-card-content_private {
  &_card {
    &_content {
      margin-bottom: $base-margin/2;
    }
  }
}
@mixin create-row-card-header_private {
  &_card {
    &_header {
      padding: $base-padding/5 $base-padding/2;
    }
  }
  &_header {
    background-color: $brand-color-4;
    ul {

      // @include make-row;
      @include make-row;
      padding-top: $base-padding/6;
      padding-top: $base-padding/6;
      text-transform: uppercase;
      > li {
        @include make-one-fifth;
        float: left;
        text-align: center;
        &:nth-child(2) {

          // @include make-two-fifth;
          width: 30%;
          text-align: left;
        }
        &:last-child {

          // @include make-two-fifth;
          width: 30%;
          text-align: right;
        }
      }
    }
  }
}
@mixin create-row-card-footer_private {
  &_footer {
    @include make-row;
  }
}
@mixin create-row-card-with-header_private {
  @include create-row-card;
  @include create-row-card--header;
}
@mixin create-row-card-with-header-footer_private {
  @include create-row-card;
  @include create-row-card-header;
  @include create-row-card-footer;
}

@mixin  create-row-card { 
	@include create-row-card_private; 
}
@mixin  create-card-content { 
	@include create-card-content_private; 
}
@mixin  create-row-card-header { 
	@include create-row-card-header_private; 
}
@mixin  create-row-card-footer { 
	@include create-row-card-footer_private; 
}
@mixin  create-row-card-with-header { 
	@include create-row-card-with-header_private; 
}
@mixin  create-row-card-with-header-footer { 
	@include create-row-card-with-header-footer_private; 
}