.pos-trans-manager-list {
	
	&__header-container {
		cursor : pointer;
	}
	
	&__bookings {
		@include create-row-card;
		@include create-card-content;
		
	    &_card {
	    	box-shadow: $row-shadow-color;
	    	@include clearfix;
	    }
	    &_row {
	    	@include clearfix;
    		color: $gray-dark;
    		
	    	&:hover {
	    		color: black;
				background-color: $brand-color-3;
	    	}
    	}
	}
	
	&__bookings_row:hover &__details:nth-child(even) {
		background-color: $brand-color-3;
	}
	&__bookings_row:hover &__details:nth-child(odd) {
		background-color: $brand-color-3;
	}
	
	&__status-msgs{
	    text-align: center;
       	padding : 1rem;
        font-size: $font-size-h3;
	}
	
	&__bookings {
	    &_card {
	    	padding: 0;
	    }
	}

	&__bkg-header{
    	@include clearfix;
	    width : 100%;
		padding: 5px 5px 0px 5px;
		
		&_bkg-number{
		    float: left;
		    width: 20%;
		    color: $brand-color-2;
			font-size: 1rem;
		}
		&_passenger-name{
			width: 30%;
    		float: left;
    		min-height: 1px;
    		padding-left: 0.3rem;
    		padding-right: 0.3rem;
			font-size: 14px;
    		font-weight: 500;
			text-transform: capitalize;
		}
		&_booking-date, &_travel-dt{
			float: left;
    		width: 15%;
    		min-height: 1px;
    		padding-left: 0.3rem;
    		padding-right: 0.3rem;
		}
		
		&_audit-date{
			float: left;
		    width: 30%;
			font-size: 0.8125rem;
		}
		&_rfq-status{
			float: left;
		    width: 15%;
			font-size: $font-size-h4;
			color: #0000cd;
			height: 1.5625rem;
		}
		&_buying-amount,&_total-amount {
		    float: left;
		    width: 20%;

			font-size: 1rem;
		    text-align: right;
		    
		    &_currency {
		    	font-size: 0.75rem;
		    }
		    
		    &_amount {
	    		font-size: 1.125rem;
    			color: $brand-color-1;
		    }
		}
		&_total-amount{
			width: 10%;
    		float: right;
    		padding-left: 0.3rem;
    		padding-right: 0.3rem;
			text-align: inherit;
		}
		&_selling-amount, &_multiple-currency {
		    float: right;
		    width: 15%;
			font-size: 1rem;
		    text-align: right;
		}
		&_multiple-currency {
			color: $brand-color-2;
		}
		
	}
	
	&__customer-details {
		@include clearfix;
	    width: 100%;
	    padding: 5px 5px 0px 5px;
		font-size: 0.8125rem;
    	
		&_name {
			float: left;
			width: 20%;
    	}
    	
    	&_travel-dt {
			float: left;
			width: 40%;
    	}
	}
	
	&__details:nth-child(even) {
		background-color: $brand-color-11;
	}
	&__details:nth-child(odd) {
		background-color: $gray-lighter;
	}
	
	&__details {
		@include clearfix;
	    width: 100%;
	    font-size:0.875rem;
	    display: table;
	    
	    &_details-container,&_details-highlighted-container {
			@include clearfix;
	    	width: 100%;
	    	cursor : pointer;
	    	padding: 0.375rem;
	    }
		&_guest-house-details-container{
			@include clearfix;
	    	width: 100%;
	    	padding: 0.375rem;
		}
	    &_details-highlighted-container{
		   background-color: $cashier-liability-color;
	    }
	    .details-container {
			&__product-type, &__product-details-type {
				float: left;
		    	width: 10%;
		    	line-height: 25px;
		    	
		    	.product-details__product-title {
					float: left;
		    	}
		    	.product-details__icon {
		    		float: left;
		    		color: $brand-color-2;
		    	}
			}
			&__product-details-type {
				float: left;
    			font-size: 1rem;
    			width: 20%;
			}
			&__booked-no {
				float: left;
		    	width: 10%;
		    	line-height: 1.5625rem;
    			min-height: 1.5625rem;
    			color: $brand-color-2;
			}
			&__product-desc {
				float: left;
		    	width: 25%;

		    	line-height: 25px;
    			height: 25px;
    			display: grid;
    			>div {
	    			text-overflow: ellipsis;
				    overflow: hidden;
				    white-space: nowrap;
    			}
			}
			&__tkt-time-limit {
				float: left;
		    	width: 10%;
		    	line-height: 1.5625rem;
    			min-height: 1.5625rem;
    			text-align: right;
			}
			&__status {
				float: left;
		    	width: 15%;
		    	text-align: right;
		    	color: $gray;
		    	line-height: 25px;
		    	min-height: 25px;
			}
			&__price, &__multiple-price {
				float: left;
		    	width: 15%;
		    	text-align: right;
		    	line-height: 25px;
    			height: 25px;
		    	
		    	.price__price-details {
		    		&_currency {
		    			font-size: .75rem;
		    		}
		    		&_amount {
		    			font-size: 1.125rem;
    					color: $brand-color-1;
		    		}
		    	}
			}
			&__total-price {
				float: right;
   				padding-left: 0.3rem;
    			padding-right: 0.3rem;
    			width: 10%;
    			text-align: inherit;
			}
		    &__price-container{
		    		width:30%;
		    }
		    
		    &__multiple-price {
		    	text-overflow: ellipsis;
			    overflow: hidden;
			    white-space: nowrap;
	    		padding-left: 0.5rem;
			    
			    &_multiple-price-container {
			    
			    	.multiple-price-container {
					    &__currency {
					    	font-size: .75rem;
					    }
					    &__amount {
    						color: $brand-color-1;
					    	font-size: 1.125rem;
					    }
			    	}
			    
			    }
			    
			    &_multiple-label {
			    	color: $brand-color-2;
			    }
		    }
			&__cash-earned {
	    	    width: 14%;
				height: 20%;
				float: left;
				margin-left: 0.5rem;
		    }
	    }

		&_productList{
		    @include clearfix;
  		    padding-left: 1rem;
			width : 100%;
		}
	}
	
	&__bkg-details{
		@include clearfix;
	}
	
	&__loader{
		@include create-loader-background;
		margin-left: 0;
		margin-right: 0;
	}
	
	&__content{
		&_headers{
		    @include clearfix;
	   		padding: $base-padding/6 $base-padding/6;
	   		margin-bottom: $base-margin/2;
	   		box-shadow: 0 0 10px rgba(0, 0, 0, .25);
			background-color: $brand-color-3;
	    	text-transform: capitalize;
		    color: $brand-color-2;
		}
		.headers{
			text-transform: uppercase;

			&__booking-no, &__description, &__rfq-status, &__buyingAmt , &__sellingAmt, &__other-details, &__status, &__passenger-info, &__booking-date, &__travel-date , &__amount {

				float: left;
				min-height: 1px;
			    padding-left: 0.3rem;
			    padding-right: 0.3rem;
			}
			&__booking-no {
				width: 20%;
			}
			&__description, &__passenger-info {
				width: 30%;
			}
			&__other-details {
				width: 7%;
			}
			&__rfq-status {
				width: 7%;

			}
			&__booking-date, &__travel-date {
				width: 15%;
			}
			&__buyingAmt {
				width: 14%;
				text-align: right;
			}
			&__amount {
				width: 10%;
    			float: right;
			}
			&__sellingAmt{
				width: 15%;
				text-align: right;
			}
			&__status {
				width: 14%;
				text-align: right;
			}
		}
		
	}

	&__search , &__remote-filter {
		@include clearfix;
	}
	&__action-bar{
      @include clearfix;
	  width:100%;
	}
	&__header {
		@include clearfix;
		@include make-header-title;
		width : 100%;
		&_title{
			width : 30%;
			float:left;
		}
		&_search-box{
		    float: right;
		    margin-top: 0.5rem;
		}
		
		&-with-remote-filter{
		    width:75%;
		}
		
		&_action-bar {
			float: right;
			width:100%;
			position:relative;
		}
	}
	&__pagination{
		margin-top:0.625rem;
		margin-bottom:0.625rem;
		width: 25%;
    	float: right;
	}
	
	&__transaction-count{
		margin-top:0.625rem;
		margin-bottom:0.625rem;
		width: 25%;
   		float: left;
	}
}

.fa-info-circle{
    color: $brand-color-19;
	font-size: 17px;
}

.custom-dynamic-popover-class {
  color: $brand-color-1;
}
