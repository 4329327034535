@mixin title_private{
	width: 100%;
	color : $brand-color-2;
	margin-bottom: 0;
}


@mixin  title{ 
	@include title_private; 
}

.pos-full-refund-form{
	@include create-row-card;
	@include create-main-wrapper;
	@include create-card-content;
    
    &_card{
		box-shadow: $row-shadow-color;
		padding-top : $base-padding/2 ;
		padding-bottom : $base-padding/2 ;
	}
	
	&__content{
		@include clearfix;
		padding: $base-padding/2;	
	    border: 5px solid $brand-color-3;
	    width: 100%;
	}
	&__heading{
		color : $brand-color-2;
	    font-size: $font-size-h3;
	    text-align: center;
	    border-bottom: 1px solid $brand-color-3;
	    padding-bottom:  $base-padding/4;
	    margin-bottom:  $base-padding/4;
	}
	
	&__container{
		@include clearfix;
   		padding : $base-padding/4 0 ;
		
		&_fields{
			float:left;
			width: 48%;
			margin: 0 $base-margin/7 ; 
			
			>select{
				height: 2.375rem;
		    	padding: 8px 16px;
			}
		}
		
		&_heading{
			@include title;
			margin: 0 $base-margin/7 ; 
		}
		
		.fields{
			&__label{
				@include title;
			}
			&__data{
				width: 100%;
				border: 1px solid $light-grey;
				border-radius : 0px;
			}
		
		}
	}
	&__control-button{
	    text-align: right;
	    width :100%;
		>button{
			margin: .625rem $base-margin/7;
			&:hover {
      			background-color: darken($brand-color-2, 20);
    		}
			&:disabled {
	  			background-color: lighten($brand-color-2, 20);
	  			&:hover {
	    			background-color: lighten($brand-color-2, 20);
	  			}
	  			cursor: not-allowed;
			} 
			@include  make-button($light, $brand-color-2,capitalize);
			@include  button-size(0, 0, 20px, inherit, 0, 25%);
	    }
	}
	
	.disabled{
		cursor: not-allowed;
		background-color: $disabled-field-color;
	}
	
	&__error-msg{
		color: $brand-color-1;
	}
	
}
