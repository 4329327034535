.pos-invoice-list {
	
	&__status-msgs{
	    text-align: center;
       	padding : 1rem;
        font-size: $font-size-h3;
	}
	
	&__loader{
		@include create-loader-background;
		margin-left: 0;
		margin-right: 0;
	}
	
	&__content{
		&_headers{
		    @include clearfix;
	   		padding: $base-padding/6 $base-padding/6;
	   		margin-bottom: $base-margin/2;
	   		box-shadow: 0 0 10px rgba(0, 0, 0, .25);
			background-color: $brand-color-3;
	    	text-transform: capitalize;
		    color: $brand-color-2;
		}
		.headers{
			text-transform: uppercase;
			&__booking-no, &__doctype, &__description, &__status , &__sellingAmt {
				float: left;
				min-height: 1px;
			    padding-left: 0.3rem;
			    padding-right: 0.3rem;
			}
			&__booking-no {
				width: 15%;
			}
			&__doctype {
				width: 15%;
			}
			&__description {
				width: 20%;
			}
			&__status {
				width: 30%;
				text-align: right;
			}
			&__sellingAmt {
				width: 20%;
				text-align: right;
			}
		}
		
		.payheaders{
			text-transform: uppercase;
			&__booking-no, &__description, &__status , &__sellingAmt, &__pendingAmt, &__payAmount, &__remarks {
				float: left;
				min-height: 1px;
			    padding-left: 0.3rem;
			    padding-right: 0.3rem;
			}
			&__booking-no {
				width: 15%;
			}
			&__description {
				width: 25%;
			}
			&__status {
				width: 25%;
				text-align: right;
			}
			&__sellingAmt {
				width: 14%;
				text-align: right;
			}
			&__pendingAmt {
				width: 14%;
				text-align: right;
			}
			&__payAmount {
				width: 15%;
				text-align: right;
			}
			&__remarks {
				width: 15%;
				text-align: right;
				padding-right: 10px;
			}
		}
	}

    &__checkbox {
         position: absolute;
         left: 3%;
     }
}