.refund-modal{
    max-width: 40%;
    margin-left: 30%;
    margin-top: 10%;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    	&__text{
		    color: $brand-color-2;
		    padding-bottom: $base-padding/3;
    		margin-left: -37%;
            margin-right: -37%;
            font-size: 1.3rem;
	    }
	    &__button{
    	    width: 30%;
			padding: $base-padding/5;
			background-color: $brand-color-3;
			border: none;
            font-size: 1rem;
	    }
}
.modal-open{
	.refund-modal.modal{
		overflow-x: hidden;
    	overflow-y: hidden;
	}
}