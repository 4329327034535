.pos-customer-mapping-list{
	&_card {
		@include clearfix;
		width: 100%;
		padding: 0;
	}
	
	&__wrapper {
		@include clearfix;
    }
    
	&__header {
		width: 100%;
		display : table;
		@include make-header-title;
	}
	
 	&__table{
    	background-color: $light;
    	width : 100%;
    	table-layout: fixed;
    	border: 1px solid $brand-color-4;
    	
    	&_header{
    		text-transform: capitalize;
	    	background-color: $brand-color-3;
    		color: $brand-color-2;
    	}
    	
    	&_header,&_data{
		    padding-top: $base-padding/6;
		    padding-bottom: $base-padding/6;
		    text-align: center;

    	}
    	&_data{
    		.pos-toggle-button__enabled-bar, .pos-toggle-button__disabled-bar{
    			&:hover{
    			    cursor: pointer;
    			}
    		}
    	}
    	&_no-records{
    		text-align:center;
    		font-size:1rem;
    		font-size: $font-size-h3;
    	}
    	&_row{
	    	border-bottom: 1px solid $brand-color-4;
    	}
    }
    
    &__error-msg{
    	color: $brand-color-1;
    }
    &__loader{
    	@include create-loader-background;
		margin: 0;
    }
}