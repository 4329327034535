.pos-task-history-row {

	margin: $base-margin/5 0rem;
	@include make-responsive($desktop-sm) {
		margin: $base-margin/5 $base-margin/3;
	}
	
	&__fieldset {
		&_title {
			font-size: $font-size-h5;
			color: $brand-color-2;
			@include make-responsive($desktop-sm) {
				font-size: $font-size-h4;
			}
		}

		&_activity {
			list-style: square;
			margin: 0rem $base-margin/2;
		}
	}
}