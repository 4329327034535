.pos-report-list {
	@include create-row-card;
	@include clearfix;
	
	&_card {
		float: left;
		padding : 0px;
	}	
	
	&__search {
		width: 100%;	
		padding-bottom: 0.625rem;
		@include make-header-title;
		display : table;
		
		&_quick-search {
			width: 40%;
			margin-top: $base-margin/2;
			display : table-cell;
			.quick-search {
				float: right;
			}
		}
	}
	&__loader{
		@include create-loader-background;
		margin: 0;
		float : left;
		width : 100%;
	}
	&__status-msgs{
	    text-align: center;
        font-size: $font-size-h3;
	}

	.report-accordion-data{
		color: $dark-gray;
	    padding: 0.5rem;
		&__accordion-icon{
		    float: left;
		    padding-top: 0.45rem;
		    width: 2%;
		}
	
		&__details {
			@include clearfix;
			font-size: 20px;
			
			&_title, &_desc {
			    color: $brand-color-2;
			    font-size: 1rem;
			    font-weight: 500;
			    float: left;
			    margin-top: .3rem;
			    width: 38%;
			    text-overflow: ellipsis;
			    overflow: hidden;
			    white-space: nowrap;
			}
			&_desc {
			    width: 60%;
			    color: inherit;
			} 
			.desc-expanded {
			    overflow: visible;
    			white-space: pre-line;
			}
		}
	}
	
	.panel-heading{
		padding: 0;
		background-color: $brand-color-11;
	}
 	.panel-body{
		padding: 0;
	}
	.panel-group .panel-heading+.panel-collapse>.panel-body{
	    border-top: none;
	} 	
	.panel-group {
		.panel{
		    margin-bottom: 6px;
		}	
	}
	.report-accordion:hover{
		box-shadow: $row-shadow-color;
	}
	.report-accordion {
		.accordion-toggle:focus {
			outline: none;
		}
	}
	
}
