.pos-dmo-gv-list {
	
	&__search , &__remote-filter {
		@include clearfix;
	}
	
	&__action-bar{
      @include clearfix;
	  width:100%;
	}
	
	&__size{
		color : $water-blue;
		font-size : 1rem;
		margin-top: .5rem;
	}
	
	&__header {
		@include clearfix;
		@include make-header-title;
		width : 100%;
		&_title{
			width : 35%;
			float:left;
		}
		&_search-box{
		    float: right;
		    margin-top: 0.5rem;
		}
		
		&-with-remote-filter{
		    width:75%;
		}
		
		&_action-bar {
			float: right;
			width:100%;
			position:relative;
		}
		&_resend{
			float: right;
    		margin-top: .5rem;
		}
		&_remote-filter{
			float: right;
	    	height: 0.0625rem;
	    	margin-right: 17rem;
		}
	}
	
	&__status-msgs{
	    text-align: center;
       	padding : 1rem;
        font-size: $font-size-h3;
	}
	
	&__loader{
		@include create-loader-background;
		margin-left: 0;
		margin-right: 0;
	}
	
	&__content{
		&_headers{
		    @include clearfix;
	   		padding: $base-padding/6 $base-padding/6;
	   		margin-bottom: $base-margin/2;
	   		box-shadow: 0 0 0.625rem rgba(0, 0, 0, .25);
			background-color: $brand-color-3;
	    	text-transform: capitalize;
		    color: $brand-color-2;
		}
		.headers{
			text-transform: uppercase;
			&__booking-no, &__dmo-gv-no, &__audit-date, &__status , &__total-amount, &__available-amount, &__serial-number {
				float: left;
				min-height: 0.0625rem;
			    padding-left: 0.3rem;
			    padding-right: 0.3rem;
			}
			&__booking-no {
				width: 13%;
			}
			&__status, &__audit-date, &__dmo-gv-no, &__serial-number {
				width: 14%;
				text-align: center;
			}
			&__total-amount, &__available-amount {
				width: 15%;
				text-align: right;
			}
		}
	}
	
	&__pagination{
		margin-top: $base-padding/3;
		margin-bottom: $base-padding/3;
		width: 25%;
    	float: right;
	}
	
	&__transaction-count{
		margin-top: $base-padding/3;
		margin-bottom: $base-padding/3;
		width: 25%;
   		float: left;
	}
	
	&__reset-filter-button {
		margin-right: 0.625rem;
	}
}