.pos-operator-refund-details{
	@include create-main-wrapper;
	
	&__content{
		 @include clearfix;
		
		&_product-info{
		    @include clearfix;
			margin : $base-padding/3;
	    }
	}
}