.pos-refund-cancellation-charges-form{
	@include create-main-wrapper;
	
	&__content{
		@include clearfix;
	    width: 100%;
	    
		&_refund-charges-data{
			width: 100%;
			@include clearfix;
		}
	}
		
	.refund-charges-data{
		&__container{
			width: 18.5%;
			float: left;
			margin : $base-margin/3 $base-margin/7;
			height: 4.5rem;

			&_label{
				@include title;
			}
			&_data{
				width : 100%;
				padding : $base-margin/4;
				border: 1px solid $light-grey;
				text-align: right;
			}
		}
	}
}
