@import url(https://fonts.googleapis.com/css?family=Raleway:700,500,600|Open+Sans:400,600,700);
// Bootstrap Colors
$gray-base:                 #000 !default;
$gray-darker:               lighten($gray-base, 13.5%) !default; // #222
$gray-dark:                 #373a3c !default;
$gray:                      #55595c !default;
$gray-light:                #818a91 !default;
$gray-lighting:             lighten($gray-base, 75%) !default;
$gray-lighter:              #eceeef !default;
$gray-lightest:             #f7f7f9 !default;

// Colors
$light-gray:                #f0f5f8 !default;
$light-grey:                #d0d0d0 !default;
$sky-blue:                  #c7e5f6 !default;
$water-blue:                #087dc2 !default;
$aqua-blue:                 #80CEFE !default;
$dark-gray:                 #838383 !default;
$royal-red:                 #ec2227 !default;
$pale-green:                #D6EFB6 !default;
$light-green:				#dff0d8 !default;
$green:						#3c763d !default;
$lighter-sky-blue:			#87ceeb !default;

$light-green:				#dff0d8 !default;
$green:						#3c763d !default;

$dark-blue:                 #3b5998 !default;
$dark-orange:               #dd4b39 !default;

$dark-green:				#008000 !default;
$bar-green:					#90ee90 !default;
$medium-violet-red:           #c71585 !default;
$orange-color:                #ff9800 !default; 

$dark:                      #000 !default;
$light:                     #fff !default;
$activated:					#23527c !default;
$blue:					    #0473b8 !default;
$darker-gray:            	#908e8e !default;
$white-color:				#FFFFFF !default;
$brown:						#825e44 !default;

$brand-color-1:             $royal-red ;  // $royal-red
$brand-color-2:             $water-blue;  // $water-blue
$brand-color-3:             $sky-blue ;  // $sky-blue
$brand-color-4:             $light-gray ;  // $light-gray
$brand-color-5:             $dark-gray ;  // $dark-gray
$brand-color-6:             $aqua-blue ;  // $aqua-blue
$brand-color-7:             $pale-green;  // $pale-green
$brand-color-8:             $dark-blue;  // $dark-blue
$brand-color-9:             $dark-orange;  // $dark-orange
$brand-color-10:            $light-grey;  // $light-grey
$brand-color-11:            $light; //$light
$brand-color-12:            $dark-green; //$dark-green
$brand-color-13:            $bar-green; //$bar-green
$brand-color-14:            $lighter-sky-blue; 
$brand-color-15:            $activated; 
$brand-color-16:            $blue; 
$brand-color-17:            $darker-gray; 
$brand-color-18:            $medium-violet-red;
$brand-color-19:            $orange-color;
$brand-color-20:            $white-color;
$brand-color-21:            $brown;
$brand-color-22:            $gray-dark;
 
$body-bg:                   $light !default;;
$body-color:                $dark-gray !default;

$text-color:                $dark-gray !default;
$text-muted:                $light-gray !default;

$link-color:                 $brand-color-2 !default;
$link-hover-color:           darken($link-color, 15%) !default;
$link-hover-decoration:      underline !default;

$font-size-root:            16px !default;

$font-size-base:            14px !default;

$font-size-h1:               2.25rem !default; // 36px
$font-size-h2:               1.5rem !default; // 24px
$font-size-h3:               1.125rem !default; // 18px
$font-size-h4:               0.875rem !default; // 14px
$font-size-h5:               0.75rem !default; // 12px
$font-size-h6:               0.625rem !default; // 10px
$font-size-sign-small:       0.3750rem !default; //6px

$font-size-number:           1.375rem !default; // 22px
$font-size-number-small:     1.125rem !default; // 22px

$font-weight-base:           600 !default;

$font-family-sans-serif:    "Raleway", Helvetica, Arial, sans-serif !default;

$font-family-base:          $font-family-sans-serif !default;
$font-family-number:        "Open Sans";

$line-height:               1.5 !default;

$enable-hover-media-query:  false !default;

$spacer:                     1rem !default;
$spacer-x:                   $spacer !default;
$spacer-y:                   $spacer !default;
$border-width:               .0625rem !default;

$headings-margin-bottom:     ($spacer / 2) !default;

$hr-border-color:             rgba(0,0,0,.1) !default;
$hr-border-width:             $border-width;

$grid-padding:                1.875rem !default; // 30px
$base-padding:                1.875rem !default; //30px
$base-margin:                 1.875rem !default; //30px

$advertise-bgcolor:           $light !default;

$star-rating:				  #F3C103;

$xs-screen-size:              0px;
$sm-screen-size:              1024px;
$md-screen-size:              1280px;
$lg-screen-size:              1600px;

$xs-width:                    auto; 
$sm-width:                    960px;
$md-width:                    1200px;
$lg-width:                    1400px;

$modal-xs-size:               320px !default;
$modal-sm-size:               900px !default;
$modal-md-size:               1000px !default;
$modal-lg-size:               1200px !default;

$disabled-field-color:		   	  #E8E9EA !default;


$confirmed-text-color:			  green !default;
$waiting-text-color:		   	  #FFBF00 !default;
$cancelled-text-color:		      red !default;

$ticketed-row-color:			lighten(green,70);//#e6ffe6;
$cancelled-row-color:			$brand-color-4;
$row-shadow-color:                  0 0 15px rgba(0, 0, 0, .25) !default;
$input-border-color:               lighten($brand-color-5, 30);

$alert-success-color: $green;
$alert-error-color: $royal-red;
$alert-warning-color: $brown;
$alert-info-color: $water-blue;
$cashier-liability-color:			 #fad8d8;
