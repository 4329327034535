.pos-system-user-form{

	@include create-row-card;
	@include create-main-wrapper;
	@include create-card-content;
	@include create-form-row-container;
	@include create-form-row-details;
		
	&__wrapper {
		@include clearfix;
		float: left;
		width: 100%;
    }
    
    &_card{
		box-shadow: $row-shadow-color;
	}
	&_content{
		@include create-nav-tabs;
	}	
    
    &__form-wrapper{
		@include clearfix;
    }
    
    &__form {
    	float: left;
    	width: 100%;
    	@include make-header-title;
    	display : table;
    	padding-bottom: $base-padding/3;
    }
    
    &__error-msg{
    	color: rgba(192,0,0,1);
    }
    
    &__heading{
	    font-size: 2.5rem;
	    color: $brand-color-2;
	    padding: 0rem 0.9rem;    
    }
    
    @include create-save-button(1rem,1rem,1rem,4rem, 20px, $brand-color-11, $brand-color-1,capitalize, 33%);
    
    select{
   		    color: inherit;
   		    border-radius : 0px;
   		    font-size: inherit;
   	}
   	
   	&__text{
	    font-size: 1.2rem;
	    padding: 1rem 1rem 0 1rem;  
	    color: $brand-color-2; 	
   	}
   	
   	&__details{
   		@include clearfix;
   		padding-bottom: 1rem;
   	}
    
    &__loader{
		@include create-loader-background;
		margin: 0;
	}
	
}