.pos-deposit-note-list-container {
	@include create-main-wrapper;
  &_wrapper {
    @include clearfix;
  }
  width: 100%;

  &__header {
    width: 100%;
    display: table;
    @include clearfix;
    @include make-header-title;
    @include make-action-bar;
    &_action-bar {
      padding-bottom: $base-padding/3;
      width: 50%;
      float:left;
      &-button{
	   background-color:$brand-color-2;
      }
      .action-bar {
        &__add-button {
	      padding-right: 0.625rem;
          > button{
	      @include make-button($light, $brand-color-2, capitalize, $light, darken($brand-color-2,20));
            @include button-size(0.25rem, 0.25rem, $font-size-h5, inherit, 0.3125rem, 100%); 
            @include make-button($light, $brand-color-2, capitalize, $light, darken($brand-color-2,20));
            min-width:7.5rem;
            font-size: 0.9375rem;
          }
          &_selected-filter-count{
          	@include round-icon-with-count;
          	margin-top: 0rem;
          	float: right;
          }
        }

      }
      @include make-responsive($desktop-sm){
        padding-bottom: $base-padding/3;
        width: 50%;
      }
    }	
    
    
    &_title{
        @include make-responsive($desktop-sm){
          width: 25%;
          display: block;
          float: left;
        }
    }
    &_record-list{
	    @include make-responsive($desktop-sm){
          width: 25%;
          float: left;
          display: block;
          text-align:center;
          padding-top:1rem;
        }
    }
  }
  &__pagination {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
	width: 50%;
    float: right;
	    @include make-responsive($desktop-sm){
		    width: 25%;
      	}
  }
}

