@mixin create-row-table_private {
	&__table {
	  background-color: $light;
	  border-collapse: collapse;
	  overflow: auto;
	  width: 100%;
	  table-layout: fixed;
	  tr {
	    padding: $base-padding/6;
	    border-bottom: 1px solid $brand-color-4;
	  }
	  tr:last-child {
	    border-bottom: 0;
	  }
	  th {
	    border-bottom: 0;
	    text-transform: capitalize;
	    color: $brand-color-2;
	    background-color: $brand-color-3;
	  }
	  td,
	  th {
	    padding-left: $base-padding/6;
	    padding-top: $base-padding/6;
	    padding-bottom: $base-padding/6;
	    text-align: center;
	  }
	}
}

@mixin  create-row-table { 
	@include create-row-table_private; 
}