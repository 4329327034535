.pos-cashier-payment-list{
	
	.cashier-payment-list{
	    @include create-row-card;
	    @include make-row;
		@include create-card-content;
    	@include clearfix;
	    
	    &_card{
	    	box-shadow: $row-shadow-color;
			padding : 0;
			background: $light;
	    	color: $gray-dark;
		}

		&_row{
			&:hover {
		    	color: black;
				background-color: $brand-color-3;
	    	}
		}
	}

	
	&__content{
		&_headers{
		    @include clearfix;
			@include make-row;
	   		padding: 0;
	   		margin-bottom: $base-margin/2;
	   		box-shadow: 0 0 10px rgba(0, 0, 0, .25);
			background-color: $brand-color-3;
	    	text-transform: capitalize;
		    color: $brand-color-2;
		}
		&_cashier-payment-list{
		}
	}
	 .headers{
    	text-transform: none;
    	&__bkg-desc, &__trans-desc, &__itinerary-info, &__pax-info, &__selling-amount {
			@include make-one-fourth;
		    padding: 0.3rem;
		    width: 20%;
    	}
    	&__selling-amount{
    		width: 10%; 
    	}
    }
    .cashier-payment-list{
    	&__cashier-payment-dtls{
    		
	    	&_bkg-dtl{
				@include clearfix;
				width : 100%;
				padding: 0.3125rem;
				
			}
			.bkg-dtl{
				&__bkg-reference-dtl{
			    		width: 20%;
					 	float: left;
			
					&_bkg-number{
						font-size: 1rem;
			    		color: $brand-color-2;
					}
					&_gds-pnr{
						margin-top: .3rem;
					}
				}
				&__date{
					float: left;
				    width: 20%;
					font-size: 0.8125rem;
					
					&_dept-date{
						margin-top: 0.6rem;
					}
				}
				&__product-desc{
					float: left;
				    width: 20%;
				    
				    &_carrier{
						margin-top: 0.4rem;
				    }
				}
				&__pax-info{
				    width: 20%;
					float: left;
					
					&_pax-dtl{
				    	width: 100%;
						
						.pax-dtl{
							&__name, &__status {
								float: left;
							}
							&__name{
							    white-space: nowrap;
	    						overflow: hidden;
	    						text-overflow: ellipsis;
							    width: 50%;
							    min-height: 0.0625rem;
							}
							&__status{
							    width: 50%;
								text-transform: uppercase;
							}
						}
					}
				}
				&__price{
					width: 10%;
					float: left;
					.price__price-details {
			    		&_currency {
			    			font-size: .75rem;
			    		}
			    		&_amount {
			    			font-size: 1.125rem;
	    					color: $brand-color-1;
			    		}
		    		}
				}
				&__add-button{
					width: 10%;
					float: left;
					@include clearfix;
					>button{
						@include  make-button($light, $brand-color-2 , none);
						margin-right: 6%;
		    			float: right;
		    			border-radius: 0.25rem;
		    			width: 5rem;
		    			height: 2rem;
					}
					.submit_button:disabled:hover {
    					cursor: not-allowed;
					}
				}
				
			}
    	}
 
    }
    
    &__header {
    	width : 100%;
		display : table;
    	@include make-header-title;
    	@include clearfix;

		&_title{
			width : 50%;
			float:left;
		}
		&_search-box{
		    float: right;
		    margin-top: 0.5rem;
		}
		
		&-with-remote-filter{
		    width:75%;
		}
		
		&_action-bar {
			width:100%;
			position:relative;
		}
    }
    
    &__details:nth-child(even) {
		background-color: $brand-color-11;
	}
	&__details:nth-child(odd) {
		background-color: $gray-lighter;
	}
	
	&__details {
		@include clearfix;
	    width: 100%;
	    font-size:0.875rem;
	    
	    &_details-container,&_detail-highlighted-container {
			@include clearfix;
	    	padding: 0.375rem;
	    }
	    &_detail-highlighted-container{
		   background-color: $cashier-liability-color;
	    }
	    .details-container {
			&__product-type,{
				float: left;
		    	width: 10%;
		    	line-height: 1.5625rem;
		    	
		    	.product-details__product-title {
		    		padding : 0 $base-padding/6;
		    	}
		    	.product-details__icon {
		    		float: left;
		    		color: $brand-color-2;
		    	}
			}
			&__product-desc {
				float: left;
		    	width: 30%;
		    	line-height: 1.5625rem;
    			height: 1.5625rem;
    			>div {
	    			text-overflow: ellipsis;
				    overflow: hidden;
				    white-space: nowrap;
    			}
			}
			
			&__status {
				float: right;
		    	width: 15%;
		    	color: $gray;
		    	line-height: 1.5625rem;
    			height: 1.5625rem;
    			margin-right: 4rem;
			}
			&__price {
				float: left;
		    	width: 20%;
		    	text-align: right;
		    	line-height: 1.5625rem;
    			height: 1.5625rem;
		    	
		    	.price__price-details {
		    		&_currency {
		    			font-size: .75rem;
		    		}
		    		&_amount {
		    			font-size: 1.125rem;
    					color: $brand-color-1;
		    		}
		    	}
			}
	    }

		&_productList{
		    @include clearfix;
  		    padding-left: 1rem;
			width : 100%;
		}
	}
	.pos-cashier-payment-details{
		&__status-msgs{
		    text-align: center;
	       	padding : 1rem;
	        font-size: $font-size-h3;
		}
	}
}