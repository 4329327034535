.pos-refund-list{
	
	.refund-list{
	    @include create-row-card;
	    @include make-row;
		@include create-card-content;
    	@include clearfix;
	    
	    &_card {
	    	box-shadow: $row-shadow-color;
			padding : 0;
			background: $light;
	    	color: $gray-dark;
		}
		
		&_row {
			&:hover {
		    	color: black;
				background-color: $brand-color-3;
	    	}
		}
	}

	
	&__content{
		&_headers{
		    @include clearfix;
			@include make-row;
	   		padding: 0;
	   		margin-bottom: $base-margin/2;
	   		box-shadow: 0 0 10px rgba(0, 0, 0, .25);
			background-color: $brand-color-3;
	    	text-transform: capitalize;
		    color: $brand-color-2;
		}
		&_refund-list{
		}
	}
	 .headers{
    	text-transform: none;
    	&__bkg-desc, &__trans-desc, &__itinerary-info, &__pax-info {
			@include make-one-fourth;
		    padding: 0.3rem;
    	}
    	&__bkg-desc{
    		width: 17%; 
    	}
    	&__trans-desc{
    		width: 25%; 
    	}
    	&__itinerary-info{
    		width: 25%; 
    	}
    	&__pax-info{
    		width: 33%; 
    		&_name{
	    		float : left;
	    		width: 50%; 
    		}
    		&_status,
    		&_charges-status
			{
	    		float : left;
	    		width: 25%; 
    		}
			&_charges-status {
				text-align: center;
			}
    	}
    }
    .refund-list{
    	&__refund-dtls{
    		cursor: pointer;
    		
	    	&_bkg-dtl{
				@include clearfix;
				width : 100%;
				padding: 5px 5px 0px 5px;
				
			}
			.bkg-dtl{
				&__bkg-reference-dtl{
			    		width: 17%;
					 	float: left;
			
					&_bkg-number{
						font-size: 1rem;
			    		color: $brand-color-2;
					}
					&_gds-pnr{
						margin-top: .3rem;
					}
				}
				&__date{
					float: left;
				    width: 25%;
					font-size: 0.8125rem;
					
					&_dept-date{
						margin-top: 0.6rem;
					}
				}
				&__product-desc{
					float: left;
				    width: 25%;
				    
				    &_carrier{
						margin-top: 0.4rem;
				    }
				}
				&__pax-info{
				    width: 33%;
					float: left;
					
					&_pax-dtl{
				    	width: 100%;
						
						.pax-dtl{
							&__name, 
							&__status,
							&__charge-status 
							{
								float: left;
							}
							&__name{
							    white-space: nowrap;
	    						overflow: hidden;
	    						text-overflow: ellipsis;
							    width: 50%;
							}
							&__status,
							&__charge-status
							{
							    width: 25%;
								text-transform: uppercase;
							}
							&__charges-status {
								text-align: center;
							}
						}
					}
				}
				
			}
    	}
 
    }
    
    &__header {
    	width : 100%;
		display : table;
    	@include make-header-title;
    	
    	&_quick-search {
    		float: right;
    	}
    }

	.refund-logo-width {
    	width : 10%;
    }
}