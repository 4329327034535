.b2b-registration-form
{
	@include create-main-wrapper;
    position: relative;
	
	&_wrapper {
		@include clearfix;
		margin: auto;
	    width: 720px;
    }
    
    &__title {
    	font-size: 2rem;
    	text-align:center;
    }
	@include create-row-card;
	
	&_card{
		box-shadow: $row-shadow-color;
	    padding: 10px 3rem;
        background-color: $brand-color-4;
        margin-top:0.75rem;
	};
	
	&__header{
    	text-transform: none;
   		 color: $brand-color-2;
   		 padding: $base-padding/6 $base-padding/2;
	     text-align: center;
   		 
   		 &_text{
		    font-size: 1.5rem;
	     	font-weight: 700;
   		 }
   		 	
	}
	&__content{
		margin-bottom: $base-padding/2;
	}
	
	.registration-form {
		
		margin-left: 0.5rem;

  		@include create-row-card;
  		@include create-row-card-header;
  		@include create-card-content;
  		
  		label{
  			color: $brand-color-2;
			margin-bottom:0;	
  		}
  		
  		input, select , textarea, button{
  			width:100%;
  			vertical-align:middle;
  		}
  		input, select{
  			height:30px;
  		}
  		
  		select{
  			border-radius:0;
  		}
 
  		
  		&__container{
  		   &_name-container{
  		   		float: left;
    			width: 100%;
	  		    .name-container{
	  		    		@include clearfix;
    				&__title{
    					float: left;
    					width: 20%;
    					padding-right:0.5rem;
    					min-height: 4.75rem;
    						&_dropdown{
    							border-radius: 0;
    							width: 100%;
    							padding-right:0;
    						}
    				}
    				&__fname{
    					float: left;
    					width: 40%;
    					padding-right:0.5rem;
    					min-height: 4.75rem;
    				}
    			
    				&__lname{
    					padding-right:0.5rem;
    					float: left;
    					width: 40%;
    					min-height: 4.75rem;
    				}
    				
    				&__header{
	  						font-size:1.25rem;
	  						color:$brand-color-2;
	  						font-weight: bold;
	  				}
    		
  				}
  			}
  			
  			&_contact-details{
  				float: left;
    			width: 100%;
  				.contact-details{
						@include clearfix;
  					&__content{
		  				min-height: 4.75rem;
	  					float: left;
    					width: 50%;
	  					padding-right:0.5rem;
	  					.form-control{
	  						border-radius:0;
	  					 }
						.info-icon{
							font-size: 1rem;
    						color: $brand-color-2;
						}			
	  					}
	  				&__header{
	  						font-size:1.25rem;
	  						color:$brand-color-2;
	  						font-weight: bold;
	  					}	
  				
  				}
  			}
  			
  			&_address-details{
  				float: left;
    			width: 100%;
  				.address-details{
  							@include clearfix;
  					&__content{
		  				min-height: 5.5rem;
		  				float: left;
    					width: 50%;
	  					padding-right:0.5rem;
	  					}
	  				&__city-content,&__zip-code-content{
	  					min-height: 4.75rem;
		  				float: left;
    					width: 50%;
	  					padding-right:0.5rem;
	  				}	
	  				&__header{
	  						font-size:1.25rem;
	  						color:$brand-color-2;
	  						font-weight: bold;
	  					}	
  				}
  			}
  			
  			&_financial-details{
	  				float: left;
    				width: 100%;
	  				.financial-details{	
	  						@include clearfix;
		  				&__content{
		  				min-height: 4.75rem;
	  					float: left;
    					width: 50%;
	  					padding-right:0.5rem;
	  						.pan-container-data,.gst-container-data{
								text-transform:uppercase;
							 }
	  					}
	  					
	  					&__header{
	  						font-size:1.25rem;
	  						color:$brand-color-2;
	  						font-weight: bold;
	  						
	  					}
  					}	
  			}
  			 	
 			&_button-container{
 				float: left;
    			width: 100%;
    			padding-top: 0.5rem;
    			padding-bottom: 0.5rem;
 				.button-container{
 						@include clearfix;
   		 			padding-top: 0.3125rem;
    				padding-bottom: 0.3125rem;
   					width: 100%;
   					&__login-container{
   						float: left;
    					width: 50%;
   						color:$brand-color-2;
   						padding-right:0.5rem;
   						cursor: pointer;
   					   }
   					
   					&__register-container{
   							@include clearfix;
   						float: left;
    					width: 50%;
   						padding-right:0.5rem;
	 	    			&_register{
 		    				@include make-button($light, $brand-color-1,capitalize,$light,darken($brand-color-1, 20));
 		    				padding-top: 0.3125rem;
    						padding-bottom: 0.3125rem;
 	    					}
   						}
    			  }
 	    	 }   
 	   }
	.wrapper{
		&__error{
			color: $brand-color-1;
			font-weight:100;
			font-size: $font-size-h6;
		}
	} 	
 }
}


