.pos-dmo-gv-row{

	@include create-row-card;
	@include create-card-content;
	box-shadow: $row-shadow-color;
	@include clearfix;
	padding: 0 ;
    margin-bottom: .9375rem;
    background-color: $light;
    color: $gray-dark;
    	
    &__header{
    	@include clearfix;
		width : 100%;
		padding: 0.3125rem 0.3125rem 0 0.3125rem;
		
		&_booking-number, &_dmo-gv-number, &_audit-date, &_status, &_serial-number {
			float: left;
			text-align: center;
			width: 14%;
			height: 1.5625rem;
			.info-icon {
				padding-left: 0.65rem;
			}
		}
		&_booking-number{
			text-align: left;
			width: 13%;
		}
		&_booking-number, &_dmo-gv-number {
		    color: $brand-color-2;
			font-size: 1rem;
		}
		&_audit-date{
			font-size: 0.8125rem;
		}
		&_status {
	    	font-size: 1.125rem;
    		color: $brand-color-1;
			height: 1.5625rem;
		}
		&_total-amount,  &_available-amount{
		    float: left;
		    width: 15%;
			font-size: 1rem;
		    text-align: right;
		    &_currency {
		    	font-size: 0.75rem;
		    }
		    
		    &_amount {
	    		font-size: 1.125rem;
    			color: $brand-color-1;
		    }
		}
	}
	
	&__customer-details {
		@include clearfix;
	    width: 100%;
	    padding: 0.3125rem 0.3125rem 0 0.3125rem;
		font-size: 0.8125rem;
    	
		&_name {
			float: left;
			width: 20%;
    	}
    	
    	&_travel-dt {
			float: left;
			width: 40%;
    	}
	}
	
	&__details {
		@include clearfix;
		background-color: $gray-lighter;
	    width: 100%;
	    font-size:0.875rem;
	    
	    &_details-container {
			@include clearfix;
	    	width: 100%;
	    	padding: 0.375rem;
	    }
	    
	    .details-container {
			&__product-desc {
				float: left;
		    	width: 55%;
		    	line-height: 1.5625rem;
    			height: 1.5625rem;
			}
			&__action-buttons {
				float: left;
		    	width: 29%;
		    	color: $brand-color-2;
		    	line-height: 1.5625rem;
    			min-height: 1.5625rem;
    			text-align: right;
			}
			&__price {
				float: left;
		    	width: 15%;
		    	text-align: right;
		    	line-height: 1.5625rem;
    			height: 1.5625rem;
		    	
		    	.price__price-details {
		    		&_currency {
		    			font-size: .75rem;
		    		}
		    		&_amount {
		    			font-size: 1.125rem;
    					color: $brand-color-1;
		    		}
		    	}
			}
	    }
	}
}