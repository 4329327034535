.pos-company-details{

	@include create-row-card;
	
	&__wrapper {
		@include clearfix;
    }
    
    &_card{
		box-shadow: $row-shadow-color;
	}
	&_content{
		@include create-nav-tabs;
	}
    
	@include create-form-row-container;
	@include create-form-row-details;

	&__address-area{
		max-height:60px;
	}
	
	&__loader{
		@include create-loader-background;
		margin: 0;
	}
	&__form{
		@include make-row;
		@include make-header-title;
		padding-bottom: $base-padding/3;
	}
}

