.pos-refund-pax-details{
	@include create-main-wrapper;
	
	&__content{
		&_pax-info{
			padding : $base-padding/4 $base-padding/2;
		}
		.pax-info {
			&__pax-header {
				&_pax-charges-received {
					color: $brand-color-1;
				}
			}
		}
		&_horizontal-line{
			border-top: 1px solid $light-grey;
		}
	}
	
	.pax-info{
		&__pax-header{
			&_pax-title
			{
				font-size: 16px;
			}
			&_pax-charges-received {
				color: $brand-color-1;
				font-size: 0.875rem;
    			font-weight: 400;
			}
		}
		&__refund-form{
			padding:  $base-padding/2 0;
		}
	}
}