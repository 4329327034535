.pos-refund-details{
	@include create-row-card;
	@include create-main-wrapper;
	@include create-card-content;
    
    &_card{
		box-shadow: $row-shadow-color;
		padding-top : $base-padding/2 ;
		padding-bottom : $base-padding/2 ;
	}
	
	&__content{
	    @include clearfix;
	    
	    &_heading{
			color: $brand-color-2;
		    font-size: $font-size-h3;
		    text-align: center;
	    }
	    &_booking-no-header{
		    font-size: $font-size-h4;
		    text-align: center;
	    }
	}
	
	label{
		color : $brand-color-2;
	}
	
	a{
		color: $royal-red;
		padding: $base-padding/7;
	}	
	
	&__status-msgs{
		text-align: center;
	    padding: 1rem;
	    font-size: $font-size-h3;
	}
	
	&__loader{
		@include create-loader-background;
		margin-left: 0;
		margin-right: 0;
	}
	
	&__header{
		@include make-header-title;
		padding-bottom: $base-padding/3;
		display: table;
		width: 100%;
		
		a{
			color : $brand-color-2;
		}
		
		&_back-list-title{
			display: table-cell;
			width: 50%;
			text-align: right;
		
		}
	}
	
	.disable-link{
	      cursor: not-allowed;
	}
	.pointer-event-none{
	 	  pointer-events: none;
	}
}
