.queue-management-list-container {
	&__status-msgs{
	    text-align: center;
       	padding : 1rem;
        font-size: $font-size-h3;
	}
	
	&__loader{
		@include create-loader-background;
		margin-left: 0;
		margin-right: 0;
	}
	
	&__content{
		&_headers{
		    @include clearfix;
	   		padding: $base-padding/6 $base-padding/6;
	   		margin-bottom: $base-margin/2;
	   		box-shadow: 0 0 10px rgba(0, 0, 0, .25);
			background-color: $brand-color-3;
	    	text-transform: capitalize;
		    color: $brand-color-2;
		}
		.headers{
			text-transform: uppercase;
			&__booking-no, &__description, &__status , &__sellingAmt,&__tite {
				float: left;
				min-height: 1px;
			    padding-left: 0.3rem;
			    padding-right: 0.3rem;
			}
			&__tite {
				width: 11%;
				text-align: center;
			}
		}
	}

    &__checkbox {
         position: absolute;
         left: 3%;
     }
}