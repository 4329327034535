.dmo-gv-history {
	&__heading {
		color: $brand-color-2;		
   		font-size: 1.7rem;
	    text-align: center;
	    border-bottom: 0.065rem solid $brand-color-3;
	    padding-bottom: 0.5rem;
	    margin-bottom: 0.5rem;
		&_dmo-gv-no {
			font-size: 1.2rem;	
		}
	}
	&__header {
		@include clearfix;
		background-color: $brand-color-3;

		&_total-amount, &_usable-amount, &_expiry-date, &_booking-no, &_trans-date, &_status,&_name,&_download-date,&_reason {
			color: $brand-color-2;
			height: 4rem;
			text-align: center;
			vertical-align: middle;
			
			.header-currency {
				display: block;
	    		width: 100%;
	    		font-size: 0.85rem;
			}
			
		}
		&_name,&_download-date,&_reason{
			float:left;
			width:33.33%;
		}
	}
	&__content,&__error-message {
		border-bottom: 1px solid $brand-color-3;
		display: table;
		min-height: 3rem;
		width: 100%;
	}
	&__error-message{
		text-align: center;
		font-size: 1.2rem;
	}
	&__header , &__content {
		&_total-amount, &_usable-amount, &_expiry-date, &_booking-no, &_trans-date, &_status,&_name,&_download-date,&_reason {
			@include clearfix;
			float: left;
			font-size: 1rem;
			padding: 0.5rem;
			width: 16.66%;
			word-break: break-word;
			text-align: center;
		}
		&_name,&_download-date,&_reason{
			float:left;
			width:33.33%;
		}
	}
	&__content {
		&_booking-no {
			color: $brand-color-2;
			font-size: 1rem;
		}
		&_expiry-date, &_trans-date {
			font-size: 0.9rem;
		}
		&_total-amount, &_usable-amount {
		    line-height: 1.5625rem;
    		height: 1.5625rem;
		    
		    .price__price-details {
		    	&_currency {
		    		font-size: .75rem;
		    	}
		    	&_amount {
		    		font-size: 1rem;
    				color: $brand-color-1;
		    	}
		    }
		}
		
	}
}

.dmo-gv-history-modal {
	&__content {
		overflow-y: auto;
	    margin-bottom: 1.875rem;
		margin-top: 0;
		padding: 0;
	}
}

