.pos-pricingmodel-form {
	@include create-main-wrapper;
	@include create-row-card;	
	@include create-card-content;
	&__header{
		@include make-header-title;
		width: 100%;
		display : table;
		&_back-to-list{
			float: right;
		    font-size: 1rem;
		}
	}
	&_card{
		box-shadow: $row-shadow-color;
	}
	&_content{
		@include create-nav-tabs;
	}
	.nav-tabs>li>a{
		font-size: $font-size-h5;
	}
	
	.search-date_select {
		width: 50%;
	    min-height: 1px;
	    padding-left: .9375rem;
    	padding-right: .9375rem;
	    float: left;
	    label{
	    	color:$brand-color-2;
	    }
    }
	@include create-save-button(1rem,1rem,1rem,4rem, 20px, $brand-color-11, $brand-color-2,capitalize, 24%);
	&__save-button{
		@include clearfix;
		>button {
			margin-top: 1.5rem;
		    border-radius: 4px;
		}
		
	}
    &__form {
		@include clearfix;
		&_identity, &_pricing-scope, &_validity {
			@include clearfix;
			.identity, .pricing-scope, .validity{ 
				
				&__header{
					display: table;
					font-size: 1.2rem;
		    		padding: 1rem 0.725rem 0;
					color: $brand-color-2;
				}
				&__content{
					&_field {
						@include clearfix;
				    	
					    width: 50%;
					    float: left;
					    
					    padding: .5rem .725rem;
						
						select{
							width: 100%;
						}
						.field{
							&__radio {
								float: left;
								margin-right: 1rem;
								color: $dark-gray;
							}
					    	
							&__label {
								width:100%;
								margin-bottom: 0.25rem;
						   		color:$brand-color-2;
						   		font-size: 0.875rem;
							}
							&__value {
								width: 100%;
								padding: 0.5rem 1rem;
								background: none;
								font-size: 0.875rem;
								vertical-align: middle;
								line-height: normal;
								border: 1px solid;
								border-color: lighten($brand-color-5, 30);
								position: relative;
							}
							&__validation-error {
								color: $brand-color-1;
								height: 0;
							}
						}
					}
				}
			}
			
			.validity-label{
				padding-left: 0;
			}
		}
		&_pricing-scope, &_validity {
			float:left;
			width:50%;
		}
	}
    &__loader{
		@include create-loader-background;
		margin: 0;
	}
	.autosuggestion{
		position: relative;
		&__item {
		    &:hover,
		    &:focus {
		      cursor: pointer;
		      background-color: $brand-color-3;
		    }
		    &_title {
		      color: inherit;
		      border-bottom: solid 1px $gray-lighter;
		      margin-bottom: 0rem;
		      font-size: inherit;
		      padding-left: 15px;
		      padding-right: 15px;
		      padding-top: 10px;
		      > .autosuggest-code {
		        color: $brand-color-5;
		      }
		      > .autosuggest-country {
		        color: $brand-color-5;
		      }
		   }
		}
		&__textbox{
			display: initial; 
	    	width: 100%;
	    	border-radius: 0px;
	    	padding: $base-padding/7 ;
	    	color: inherit; 
		    border: 1px solid $light-grey;
		}
		>label{
			width: 100%;
			font-size: inherit;
		}
	}
	.itemcontainer{
		box-shadow: 1px 7px 50px 2px rgba(168,166,168,.5);
		max-height: 500px;
		overflow-y: hidden;
		z-index: 5;
		position: absolute;
		background-color: $light;
		border-top: 1px solid $brand-color-2;
		border-right: 1px solid $gray-lighter;
		border-bottom: 1px solid $gray-lighter;
		border-left: 1px solid $gray-lighter;
		margin-top: -1px;
		left: 0;
		right: 0;
		width: 100%;
	}
	.selecteditem {
		background-color: $brand-color-3;
	}
					
}