.pos-dmo-gv-update {
	@include clearfix;
	display: block;
	width: 100%;
	&__form {
		display: block;
		width: 100%;
		&_credit-note-no, &_remark {
			padding: $base-padding/2 0 $base-padding/2 0;
			.credit-note-no, .remark {
				
				&__label {
					color: $brand-color-2;
					width: 100%;
					display: inline-block;
					vertical-align: top;
				}
				
				&__input {
					width: 100%;
					display: inline-block;
					padding-top:.625rem;
					
					>textarea, >input {
						width: 50%;
					}
					
					>textarea {
						min-height: 6rem;
					}
				}
			}
		}
		&_action-selector {
			float: left;
		   	margin-top: $base-padding/2;
		   	padding-right:15px;
		   	> select {
			width: 250px;
			padding: .3rem 1rem;
			background: 0 0;
			font-size: .875rem;
			height:34px;
			vertical-align: middle;
			line-height: normal;
		}
		}
		&_action-buttons {
	   		float: left;
	   		margin-top: $base-padding/2;
			>button {
				@include make-button($brand-color-11, $brand-color-2,capitalize,$brand-color-11,darken($brand-color-2, 20));
				@include button-size(1.125rem ,1.125rem, 0.938rem, 0, 0.25rem, 30%);
				min-width: 11.25rem;
			    height: 1.875rem;
			}
		}
		.form-field-validation-error{
		    width: 100%;
	    	color: $brand-color-1;
			font-weight: 500;
    		font-size: .85rem;
		}
	}
}

.pos-dmo-gv-update-modal {
	&__header-info-value {
		color: lighten($brand-color-1,20);
  		font-size: 0.875rem;
  		margin: 0.125rem;
	}
}