.pos-task-form-container {
   
    @include create-row-card;
    @include create-card-content;

    &_card {
        box-shadow: $row-shadow-color;
    }

    &_content {
        @include create-nav-tabs;
        padding: 1rem;
        font-size: 1.125rem;
    }

    &__form-header {
        width: 100%;
        @include make-header-title;
        display: table;
        padding-bottom: $base-padding/3;
    }
}